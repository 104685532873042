@import '../../styles/variables';

.#{$category-trends-prefix}-container {
  display: flex;

  &-searches {
    display: flex;
    flex-wrap: wrap;
  }

  &-desktop,
  &-tablet {
    width: 100%;

    h2 {
      display: inline-flex;
      font-size: $font-size-18;
      font-weight: $font-weight-semibold;
      line-height: $line-height-22;
    }

    .container {
      gap: $search-spacing-16;
      margin-top: $search-spacing-10;
      display: grid;
      width: 100%;
      grid-template-columns: repeat(auto-fit, minmax(209px, 209px));
      grid-auto-rows: 1fr;
      max-width: 884px;

      .ui-category-trends-entry-container:hover {
        z-index: $z-index-base;
      }

      @media (width <= 1275px) {
        grid-template-columns: repeat(auto-fit, minmax(284px, 284px));
        gap: $search-spacing-16;
      }
    }
  }

  .container.carousel {
    justify-content: space-between;
    margin-top: $search-spacing-12;
  }
}
