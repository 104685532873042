@import '../../../styles/variables';
@import '../../../styles/common';
@import '~@andes/common/index';
@import '~@andes/card/index';
@import '~@andes/carousel-snapped/index';
@import '~@andes/breadcrumb/index';
@import '~@andes/thumbnail/index';
@import '~@andes/money-amount/index';
@import '~@andes/badge/index';
@import '~@andes/button/index';
@import './polycard';
@import '../../../components-v2/header/styles/categories-header';
@import '../../../components-v2/category-trends-carousel/category-trend-carousel';
@import '../../../components-v2/category-trend-container/category-trend-container';
@import '../../../components-v2/sidebar/styles/sidebar.desktop';
@import '../../../components-v2/sidebar/components/breadcrumb/styles/breadcrumb.desktop';
@import '../../../components-v2/sidebar/components/filters/styles/filters.desktop';
@import '../../../components-v2/icons/styles/desktop';
@import '../../../components-v2/header/styles/exhibitor-header.desktop.cat';
@import '../../../components/layout/styles/gallery.desktop';
@import '../../../components/grid-layout/styles/container-items.desktop';
@import '../../../components/card/highlight/styles/highlight-label.desktop';
@import '~ui-library_ml/src/components/carousel/styles/paged';
@import '~ui-library_ml/src/components/carousel/styles/arrows';
@import '../../../components/layout/styles/layout.desktop';

@import '~@seo-frontend-components/card-blog-carousel/index';
@import '~@seo-frontend-components/card-blog-entry/index';
@import '../../../components/seo-contents/styles/seo-contents.desktop';
@import '../../../components-v2/footer-components/styles/desktop';

body {
  background-color: $andes-gray-070-solid;
}

// sass-lint:disable no-vendor-prefixes, no-misspelled-properties
main {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ui-category-trends-desktop-content {
  display: flex;
  justify-content: center;
}

.ui-category-trends-desktop-content-main {
  display: flex;
  margin-top: 25px;
  max-width: 1215px;
  width: 100%;
  justify-content: center;
  padding: 0 10px;

  &-components {
    margin-right: 32px;
    margin-bottom: 25px;
    width: 100%;
    max-width: 884px;
    display: flex;
    flex-direction: column;
    gap: 32px;

    @media (width <= 1275px) {
      max-width: 584px;
    }
  }
}
