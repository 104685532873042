@import '../../styles/variables';
@import '~@seo-frontend-components/trends-carousel/index';

.seo-ui-trends-carousel-loading {
  height: 156px;
}

// Remove when andes is upgraded to latest versions.
.andes-carousel-snapped__pagination--light
  [data-andes-carousel-snapped-pagination-item]
  [data-andes-carousel-snapped-pagination-action] {
  background-color: $search-gray-r010;
}

.andes-carousel-snapped__pagination--light
  [data-andes-carousel-snapped-pagination-item][data-andes-carousel-snapped-pagination-item-active='true']
  [data-andes-carousel-snapped-pagination-action] {
  background-color: $search-dodger-blue;
}

.andes-carousel-snapped__pagination [data-andes-carousel-snapped-pagination-item] button {
  border: 0;
  border-radius: 50%;
  height: $search-spacing-6;
  margin: 0 $search-spacing-2;
  padding: 0;
  width: $search-spacing-6;
}
